import { booleanProp } from "@/helpers/props";
import { useUtils as useAclUtils } from "@core/libs/acl";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

const { t } = useI18nUtils();
const { canViewVerticalNavMenuHeader } = useAclUtils();

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isRender: booleanProp(),
  },
  render(h) {
    const span = h("span", {}, t(this.item.header));
    const icon = !this.isRender
      ? h("feather-icon", {
          props: {
            icon: "MoreHorizontalIcon",
            size: "18",
          },
        })
      : null;

    if (canViewVerticalNavMenuHeader(this.item)) {
      return h("li", { class: "navigation-header text-truncate" }, [
        span,
        icon,
      ]);
    }
    return h();
  },
};
