export default [
  {
    header: "Apps & Pages",
  },
  {
    title: "Người Dùng",
    icon: "UserIcon",
    route: "user-list",
  },
  {
    title: "Thông Báo",
    icon: "BellIcon",
    route: "notification-all",
  },
  {
    title: "Đơn Hàng",
    icon: "ShoppingCartIcon",
    route: "order-list",
  },
  {
    title: "Chuyển tiền",
    icon: "DollarSignIcon",
    route: "deposit-request",
  },
  {
    title: "Công nợ",
    icon: "BookmarkIcon",
    route: "debts",
  },
];
