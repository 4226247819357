import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";

import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import "firebase/remote-config";
import { fetchAndActivate, getValue } from "firebase/remote-config";
export const COLLECTION_NAME = {
  User: "user",
};

const env = process.env;
export class FirebaseService {
  firebaseApp;
  authFirebase;
  dbFireStore;
  instance;
  collection;
  doc;
  getDoc;
  setDoc;
  onSnapshot;
  remoteConfig;
  constructor() {
    if (this.instance) return this;

    this.firebaseApp = initializeApp({
      apiKey: env.VUE_APP_API_KEY,
      authDomain: env.VUE_APP_AUTH_DOMAIN,
      projectId: env.VUE_APP_PROJECT_ID,
      storageBucket: env.VUE_APP_STORAGE_BUCKET,
      messagingSenderId: env.VUE_APP_MESSAGING_SENDER_ID,
      appId: env.VUE_APP_APP_ID,
      measurementId: env.VUE_APP_MEASUREMENT_ID,
    });
    this.authFirebase = getAuth(this.firebaseApp);
    this.dbFireStore = getFirestore(this.firebaseApp);
    this.instance = this;
    this.collection = collection;
    this.doc = doc;
    this.getDoc = getDoc;
    this.onSnapshot = onSnapshot;
    this.setDoc = setDoc;
    this.remoteConfig = getRemoteConfig(this.firebaseApp);
    this.remoteConfig.settings = { minimumFetchIntervalMillis: 3600 };
  }

  async signInAnonymously() {
    await signInAnonymously(this.authFirebase);
  }

  docFirebase = (collectionName, docName) => {
    return this.doc(this.dbFireStore, collectionName, docName);
  };

  setDocFirebase = async (docFirebase, data) => {
    return await this.setDoc(docFirebase, data);
  };
  initFetchAndActivate = async () => {
    await fetchAndActivate(this.remoteConfig);
  };

  getValue = (key) => {
    return getValue(this.remoteConfig, key);
  };
}

export const firebaseService = new FirebaseService();
