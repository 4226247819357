import "@/services/api";
import VueCompositionAPI from "@vue/composition-api";
import { ModalPlugin, ToastPlugin } from "bootstrap-vue";
import Vue from "vue";

import i18n from "@/libs/i18n";
import VeeValidate from "vee-validate";
import JsonCSV from "vue-json-csv";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/acl";
import "@/libs/clipboard";
import "@/libs/portal-vue";
import "@/libs/sweet-alerts";
import "@/libs/toastification";
import "@/libs/tour";
import "@/libs/vue-google-map";
import "@/libs/vue-select";
import VueCurrencyInput from "vue-currency-input";
import { firebaseService } from "./firebase/firebase";

Vue.component("downloadCsv", JsonCSV);

const pluginOptions = {
  globalOptions: {
    min: 0,
    locale: "en-US",
    allowNegative: true,
    distractionFree: {
      hideNegligibleDecimalDigitsOnFocus: true,
      hideNegligibleDecimalDigits: true,
      hideGroupingSeparatorOnFocus: true,
      // hideCurrencySymbol: false,
      // hideGroupingSymbol: false,
    },
  },
};
Vue.use(VueCurrencyInput, pluginOptions);

//Validate field
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

(async () => {
  await firebaseService.signInAnonymously();
})();

Vue.config.productionTip = false;
Vue.use(VeeValidate);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
