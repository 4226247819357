import { formatRole, getAbility } from "@/helpers/permission";
import { errorHandler } from "@/services/toastHandler";

const state = () => ({
  loading: false,
  error: "",
});

export default {
  namespaced: true,
  state: state(),

  mutations: {
    LOADING: (s) => Object.assign(s, state(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: (s) => (s.loading = false),
  },

  actions: {
    async login({ commit, dispatch }, data) {
      commit("LOADING");
      try {
        const res = await this.$api.post("/v1/cms/user/sign-in", data);
        const user = res?.data?.data;
        if (!user) throw "Đăng nhập thất bại";
        user.roleName = formatRole(user.roleId);
        dispatch("auth/setAbility", getAbility(user.roleName), {
          root: true,
        });
        dispatch("auth/setAuth", user, { root: true });
        return user;
      } catch (error) {
        errorHandler(error);
      } finally {
        commit("LOADED");
      }
    },
  },
};
