import vuexPersist from "@/store/vuex-persist";
import Vue from "vue";
import Vuex from "vuex";

// Modules
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import app from "./app";
import appConfig from "./app-config";
import auth from "./auth";
import userAuth from "./user-auth";
import verticalMenu from "./vertical-menu";
import apps from "./apps";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    userAuth,
    appConfig,
    verticalMenu,
    "app-ecommerce": ecommerceStoreModule,
    apps,
  },
  strict: process.env.DEV,
  plugins: [vuexPersist.plugin],
});
