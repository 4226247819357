import userList from "./user-list";
import userDetail from "./user-detail";
import changePassword from "./change-password";
import updateProfile from "./update-profile";
import depositBalance from "./deposit-balance";
import changeExchangeRate from "./change-exchange-rate";
import changeDiscount from "./change-discount";
import paymentBalance from "./payment-balance";
import signup from "../users/signup";
const appsModule = {
  namespaced: true,
  modules: {
    userList,
    userDetail,
    changePassword,
    updateProfile,
    depositBalance,
    changeExchangeRate,
    changeDiscount,
    paymentBalance,
    signup,
  },
};

export default appsModule;
