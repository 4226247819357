import { errorHandler } from "@/services/toastHandler";

const initState = () => ({
  users: [],
  loading: false,
  error: "",
  totalPage: 0,
});

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_USERS: (s, users) => (s.users = users),
    SET_TOTAL_PAGE: (s, totalPage) => (s.totalPage = totalPage),
    LOADING: (s) => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: (s) => (s.loading = false),
    RESET: (s) => Object.assign(s, initState()),
  },

  actions: {
    async getRefAffiliates({ commit }, { userId, ...params }) {
      commit("RESET");
      commit("LOADING");
      try {
        const res = await this.$api.get(`v1/cms/user/ref-affiliate/${userId}`, {
          params,
        });
        const data = res?.data;
        if (!data) throw new Error();
        const { total } = data.pagination;
        commit("SET_TOTAL_PAGE", total);
        return data?.data ?? [];
      } catch (e) {
        errorHandler("Lấy dánh sách khách hàng thất bại!");
      } finally {
        commit("LOADED");
      }
    },
  },
};
