import { errorHandler } from "@/services/toastHandler";

const initState = () => ({
  userData: null,
  loading: false,
  error: "",
});

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_USER: (s, user) => (s.userData = user),
    LOADING: (s) => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: (s) => (s.loading = false),
    RESET: (s) => Object.assign(s, initState()),
  },

  actions: {
    async getOrderUserFilter({ commit }) {
      commit("RESET");
      commit("LOADING");

      try {
        const users = (await this.$api.get(`v1/cms/product/order/users/filter`))
          ?.data?.data;
        return users.length ? users : [];
      } catch (e) {
        return [];
      } finally {
        commit("LOADED");
      }
    },

    async AcceptPaymentOrder({ commit }, body) {
      commit("RESET");
      commit("LOADING");

      try {
        const res = (await this.$api.put(`v1/cms/product/order/payment`, body))
          ?.data?.data;
        if (res) return res;
        throw new Error();
      } catch (e) {
        errorHandler("Đơn hàng đã được thanh toán");
      } finally {
        commit("LOADED");
      }
    },
  },
};
