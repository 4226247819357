export default [
  {
    path: "/orders",
    name: "order-list",
    component: () => import("@/views/apps/order/order-list/Orders.vue"),
    meta: {
      pageTitle: "Danh sách đơn hàng",
      breadcrumb: [
        {
          text: "Danh sách đơn hàng",
          active: true,
        },
      ],
    },
  },
  {
    path: "/order/:orderId",
    name: "order-detail",
    component: () => import("@/views/apps/order/detail/Order.vue"),
    meta: {
      pageTitle: "Chi tiết đơn hàng",
      breadcrumb: [
        {
          to: { name: "order-list" },
          text: "Danh sách đơn hàng",
        },
        {
          text: "Chi tiết đơn hàng",
          active: true,
        },
      ],
    },
  },
];
