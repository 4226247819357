import { convertDateDMYToISOString } from "@/helpers/untils";
import { errorHandler } from "@/services/toastHandler";

const initState = () => ({
  loading: false,
  error: {},
});

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: (s) => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: (s) => (s.loading = false),
    RESET: (s) => Object.assign(s, initState()),
  },

  actions: {
    async updateProfile({ commit }, input) {
      commit("RESET");
      commit("LOADING");
      try {
        const { userId, ...payload } = input;

        const user = (
          await this.$api.put(`/v1/cms/user/${userId}`, {
            ...payload,
            ...(payload.dob
              ? { dob: convertDateDMYToISOString(payload.dob) }
              : {}),
          })
        )?.data?.data;
        if (user) return user;
      } catch (e) {
        errorHandler(e.response ? e : "Cập nhật thông tin thất bại!");
      } finally {
        commit("LOADED");
      }
    },
  },
};
