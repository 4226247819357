export default [
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("@/views/pages/contact-us/ContactUs.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/pages/contact-us/Privacy.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/pages/contact-us/Terms.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
];
