export default [
  {
    path: "/users",
    name: "user-list",
    component: () => import("@/views/apps/users/list/Users.vue"),
    meta: {
      pageTitle: "Danh sách người dùng",
      breadcrumb: [
        {
          text: "Danh sách người dùng",
          active: true,
        },
      ],
    },
  },
  {
    path: "/user/:userId",
    name: "user-sub-menu",
    component: () => import("@/views/apps/users/detail/UserSubMenu.vue"),
    meta: {
      pageTitle: "Chi tiết người dùng",
      breadcrumb: [
        {
          to: { name: "user-list" },
          text: "Danh sách người dùng",
        },
        {
          text: "Chi tiết người dùng",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/signup",
    name: "user-signup",
    component: () => import("@/views/apps/users/list/signup/Signup.vue"),
    meta: {
      pageTitle: "Tạo người dùng",
      breadcrumb: [
        {
          to: { name: "user-list" },
          text: "Danh sách người dùng",
        },
        {
          text: "Tạo Tài khoản",
          active: true,
        },
      ],
    },
  },
];
