import transactions from "./transactions";

const appsModule = {
  namespaced: true,
  modules: {
    transactions,
  },
};

export default appsModule;
