import { convertDateDMYToISOString } from "@/helpers/untils";
import { errorHandler } from "@/services/toastHandler";

const initState = () => ({
  loading: false,
  error: {},
});

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: (s) => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: (s) => (s.loading = false),
    RESET: (s) => Object.assign(s, initState()),
  },

  actions: {
    async signup({ commit }, payload) {
      commit("RESET");
      commit("LOADING");
      try {
        const res = (
          await this.$api.post(`/v1/cms/user/sign-up`, {
            ...payload,
            dob: convertDateDMYToISOString(payload.dob),
          })
        )?.data?.data;
        if (res) {
          return res;
        }
        throw new Error();
      } catch (e) {
        errorHandler(e.response ? e : "Tạo tài khoản thất bại!");
      } finally {
        commit("LOADED");
      }
    },
  },
};
