export const UserRoleEnum = {
  SuperAdmin: "superadmin",
  Admin: "admin",
  User: "user",
};

export const RoleEnum = {
  SuperAdmin: 1,
  Admin: 2,
  User: 3,
  CustomerSupport: 4,
};

export const RoleInfoParse = {
  [RoleEnum.SuperAdmin]: "Quản trị viên cấp cao",
  [RoleEnum.Admin]: "Quản trị viên",
  [RoleEnum.User]: "Khách hàng",
  [RoleEnum.CustomerSupport]: "Công Tác Viên",
};

export const UserStatusEnum = {
  Active: 0,
  Deleted: 1,
};

export const UserStatusParse = {
  [UserStatusEnum.Active]: "Active",
  [UserStatusEnum.Deleted]: "Deleted",
};
