export const UILocalize = {
  systemError: "Lỗi hệ thống!",
  username: "Vui lòng nhập tên người dùng!",
  password: "Vui lòng nhập mật khẩu!",
  fullName: "Vui lòng nhập tên!",
  email: "Vui lòng nhập email!",
  message: "Vui lòng nhập nội dung!",
  phoneNumber: "Vui lòng nhập số điện thoại!",
};

export const CodeLocalize = {
  "error.accountInvalid": "Tên người dùng hoặc mật khẩu không đúng!",
  "error.phoneNumberExisted": "Số điện thoại đã tồn tại!",
  "error.balanceNotEnough": "Số dư đã được thanh toán!",
  "error.paymentMethodNotFound": "Vui lòng thêm phương thức thanh toán!",
  "error.usernameExisted": "Tên người dùng đã tồn tại!",
  "error.emailExisted": "Email đã tồn tại!",
};

export const handleError = (errors, field) => {
  if (errors?.length > 0) {
    return UILocalize[field] || UILocalize[systemError];
  }
};
