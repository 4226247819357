import { errorHandler } from "@/services/toastHandler";

const initState = () => ({
  loading: false,
  error: {},
});

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: (s) => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: (s) => (s.loading = false),
    RESET: (s) => Object.assign(s, initState()),
  },

  actions: {
    async changeExchangeRate({ commit }, payload) {
      commit("RESET");
      commit("LOADING");
      try {
        const res = (await this.$api.put(`/v1/cms/exchange-rate`, payload))
          ?.data?.data;
        if (res) {
          return res;
        }
        throw new Error();
      } catch (e) {
        errorHandler("Điều chỉnh tỷ giá thất bại!");
      } finally {
        commit("LOADED");
      }
    },
  },
};
