import { CodeLocalize } from "@/helpers/error";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export const errorHandler = (err) => {
  const error = err.response ? err.response.data.error : err;
  if (error.isAxiosError) {
    if (error.message === "Network Error") {
      toast({
        component: ToastificationContent,
        props: {
          title: "Connection",
          icon: "BellIcon",
          text: "Please check your internet connection",
          variant: "danger",
        },
      });
      return;
    }
  }

  toast({
    component: ToastificationContent,
    props: {
      title: "Lỗi",
      icon: "AlertTriangleIcon",
      text: CodeLocalize[error?.code] || error.message || error,
      variant: "danger",
    },
  });
};

export default function useToastHandler() {
  const showToastError = (err, title) => {
    toast({
      component: ToastificationContent,
      props: {
        title: title || "Lỗi",
        icon: "AlertTriangleIcon",
        text: err ? err.message || err : null,
        variant: "danger",
      },
    });
  };

  const showToastSuccess = (title, text) => {
    toast({
      component: ToastificationContent,
      props: {
        title: title || "Thành công",
        text,
        icon: "CheckIcon",
        variant: "success",
      },
    });
  };

  return {
    errorHandler,
    showToastError,
    showToastSuccess,
  };
}
