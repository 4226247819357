export default [
  {
    path: "/debts",
    name: "debts",
    component: () => import("@/views/apps/debt/list/Debts.vue"),
    meta: {
      pageTitle: "Danh sách công nợ",
      breadcrumb: [
        {
          text: "Danh sách công nợ",
          active: true,
        },
      ],
    },
  },
];
