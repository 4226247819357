export default [
  {
    path: "/deposit-request",
    name: "deposit-request",
    component: () => import("@/views/apps/deposit-request/DepositRequest.vue"),
    meta: {
      pageTitle: "Danh sách chuyển tiền",
      breadcrumb: [
        {
          text: "Danh sách chuyển tiền",
          active: true,
        },
      ],
    },
  },
];
