import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { isUserLoggedIn } from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";
import home from "./routes/dashboard";
import pages from "./routes/pages";
import apps from "./routes/app/index";
import contact from "./routes/contact";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "csm-home" } },
    ...home,
    ...pages,
    ...apps,
    ...contact,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "auth-login" });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next("/");
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
