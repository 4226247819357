import orders from "./orders";
import order from "./order";
import update from "./update";
import quotePrice from "./quote-price";
import updateOrder from "./update-order";
import orderUserFilter from "./order-user";
const appsModule = {
  namespaced: true,
  modules: { orders, order, update, quotePrice, updateOrder, orderUserFilter },
};

export default appsModule;
