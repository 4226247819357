import { errorHandler } from "@/services/toastHandler";

const state = () => ({
  loading: false,
  error: "",
});

export default {
  namespaced: true,
  state: state(),

  mutations: {
    LOADING: (s) => Object.assign(s, state(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: (s) => (s.loading = false),
  },

  actions: {
    async pushNotificationToAllUsers({ commit }, data) {
      commit("LOADING");
      try {
        const res = await this.$api.post("/v1/cms/notification/users", data);
        if (res?.data?.data) return true;

        throw "Gửi thông báo thất bại!";
      } catch (error) {
        errorHandler(error);
      } finally {
        commit("LOADED");
      }
    },
  },
};
