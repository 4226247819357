import { errorHandler } from "@/services/toastHandler";

const initState = () => ({
  userData: null,
  loading: false,
  error: "",
});

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_USER: (s, user) => (s.userData = user),
    LOADING: (s) => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: (s) => (s.loading = false),
    RESET: (s) => Object.assign(s, initState()),
  },

  actions: {
    async getUser({ commit }, { userId }) {
      commit("RESET");
      commit("LOADING");

      try {
        const user = (await this.$api.get(`v1/cms/user/${userId}`))?.data?.data;
        if (user?.userId) return user;
        throw new Error();
      } catch (e) {
        errorHandler("Người dùng không tồn tại.");
      } finally {
        commit("LOADED");
      }
    },
  },
};
