import dashboard from "./dashboard/dashboard";
import depositRequest from "./deposit-request";
import notification from "./notification";
import order from "./order";
import refAffiliate from "./ref-affiliate";
import transaction from "./transaction";
import users from "./users";
import debt from "./debt";
const appsModule = {
  namespaced: false,
  modules: {
    users,
    notification,
    order,
    transaction,
    refAffiliate,
    depositRequest,
    dashboard,
    debt,
  },
};

export default appsModule;
