import debtList from "./debt-list";

const debtsModule = {
  namespaced: true,
  modules: {
    debtList,
  },
};

export default debtsModule;
