import refAffiliates from "./ref-affiliates";

const appsModule = {
  namespaced: true,
  modules: {
    refAffiliates,
  },
};

export default appsModule;
