export default [
  {
    path: "/notification",
    name: "notification-all",
    component: () => import("@/views/apps/notification/Notification.vue"),
    meta: {
      pageTitle: "Thông báo",
      breadcrumb: [
        {
          text: "Thông báo",
          active: true,
        },
      ],
    },
  },
];
