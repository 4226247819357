import moment from "moment-timezone";

export const customTime = (
  dateTime = new Date(),
  format = "DD-MM-YYYY HH:mm",
  timezone = "Asia/Ho_Chi_Minh"
) => {
  let dateMoment = moment.utc(dateTime);

  if (timezone) {
    dateMoment = dateMoment.tz(timezone);
  }
  return dateMoment.format(format);
};

export const formatCurrency = (amount, options = {}, numFixed) => {
  if (Number.isNaN(Number(amount))) return amount;

  const vietnamOptions = {
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  };

  if (numFixed) {
    const [int, decimal] = Number(isNaN(amount) ? 0 : amount)
      .toFixed(2)
      .split(".");
    const intFormat = Intl.NumberFormat(vietnamOptions).format(int);
    return `${intFormat}.${decimal}`;
  }
  return Intl.NumberFormat(vietnamOptions).format(amount);
};

export const genAvatarText = (name) => {
  if (!name) return "";
  const nameSplit = name.split(" ");

  if (nameSplit.length >= 2) {
    const convertName =
      nameSplit[0].charAt(0) + nameSplit[nameSplit.length - 1].charAt(0);
    return convertName.toUpperCase();
  }
  return nameSplit[0].charAt(0).toUpperCase();
};

export const compareObject = (coreObject, compareObject) => {
  if (typeof coreObject !== "object" || typeof compareObject !== "object")
    return;
  const newObj = {};
  Object.keys(coreObject).forEach((key) => {
    if (coreObject[key] !== compareObject[key] && compareObject[key]) {
      newObj[key] = compareObject[key];
    }
  });
  return Object.keys(newObj)?.[0] ? newObj : undefined;
};

export const convertDateDMYToISOString = (dateDMY, fm = "-") => {
  const dateSplit = dateDMY.split(fm);
  if (!(dateSplit?.length > 2)) return dateDMY;
  return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
};
