import { errorHandler } from "@/services/toastHandler";

const initState = () => ({
  userData: null,
  loading: false,
  error: "",
});

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_USER: (s, user) => (s.userData = user),
    LOADING: (s) => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: (s) => (s.loading = false),
    RESET: (s) => Object.assign(s, initState()),
  },

  actions: {
    async getOrder({ commit }, { orderId }) {
      commit("RESET");
      commit("LOADING");

      try {
        // throw new Error();
        const order = (await this.$api.get(`v1/cms/product/order/${orderId}`))
          ?.data?.data;
        if (order?.orderId) return order;
        throw new Error();
      } catch (e) {
        errorHandler("Đơn hàng không tồn tại!");
      } finally {
        commit("LOADED");
      }
    },
  },
};
