import { RoleEnum, UserRoleEnum } from "@/@core/utils/enums/user.enum";
import store from "@/store";

export const getAbility = (role) => {
  switch (role) {
    case UserRoleEnum.SuperAdmin:
    case UserRoleEnum.Admin:
    case UserRoleEnum.CustomerSupport:
      return [
        {
          action: "manage",
          subject: "all",
        },
      ];
    default:
      return [
        {
          action: "read",
          subject: "Auth",
        },
      ];
  }
};

export const formatRole = (roleId) => {
  switch (roleId) {
    case 1:
      return UserRoleEnum.SuperAdmin;
    case 2:
      return UserRoleEnum.Admin;
    default:
      return UserRoleEnum.User;
  }
};

export const isRoleSuperAdmin = () =>
  store.state.auth.user.roleId === RoleEnum.SuperAdmin;

export const isRoleAdmin = () =>
  store.state.auth.user.roleId === RoleEnum.Admin;
export const isRoleUser = () => store.state.auth.user.roleId === RoleEnum.User;
export const isCustomerSupport = () =>
  store.state.auth.user.roleId === RoleEnum.CustomerSupport;
